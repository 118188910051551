/* Include all fonts & customized classes */

@font-face {
  font-family: "thonburiregular";
  src: url("./assets/fonts/thonburi-webfont.eot");
  src: url("./assets/fonts/thonburi-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/thonburi-webfont.woff") format("woff"),
    url("./assets/fonts/thonburi-webfont.ttf") format("truetype"),
    url("./assets/fonts/thonburi-webfont.svg#thonburiregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "San Francisco Text";
  src: url("./assets/fonts/SF-UI-Display-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt-Thin.ttf) format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt-ThinItalic.ttf) format("truetype");
  font-weight: 100;
  font-style: italic;
}
.prompt-100 {
  font-family: "Prompt";
  font-weight: 100;
  font-style: normal;
}

.prompt-100i {
  font-family: "Prompt";
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt-ExtraLight.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt-ExtraLightItalic.ttf) format("truetype");
  font-weight: 200;
  font-style: italic;
}
.prompt-200 {
  font-family: "Prompt";
  font-weight: 200;
  font-style: normal;
}

.prompt-200i {
  font-family: "Prompt";
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt-LightItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
}
.prompt-300 {
  font-family: "Prompt";
  font-weight: 300;
  font-style: normal;
}

.prompt-300i {
  font-family: "Prompt";
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}
.prompt-400 {
  font-family: "Prompt";
  font-weight: 400;
  font-style: normal;
}

.prompt-400i {
  font-family: "Prompt";
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt-MediumItalic.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
}
.prompt-500 {
  font-family: "Prompt";
  font-weight: 500;
  font-style: normal;
}

.prompt-500i {
  font-family: "Prompt";
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt-SemiBoldItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
}
.prompt-600 {
  font-family: "Prompt";
  font-weight: 600;
  font-style: normal;
}

.prompt-600i {
  font-family: "Prompt";
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt-BoldItalic.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
}
.prompt-700 {
  font-family: "Prompt";
  font-weight: 700;
  font-style: normal;
}

.prompt-700i {
  font-family: "Prompt";
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt-ExtraBold.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt-ExtraBoldItalic.ttf) format("truetype");
  font-weight: 800;
  font-style: italic;
}
.prompt-800 {
  font-family: "Prompt";
  font-weight: 800;
  font-style: normal;
}

.prompt-800i {
  font-family: "Prompt";
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt-BlackItalic.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
}
.prompt-900 {
  font-family: "Prompt";
  font-weight: 900;
  font-style: normal;
}

.prompt-900i {
  font-family: "Prompt";
  font-weight: 900;
  font-style: italic;
}

body {
  font-family: "thonburiregular!important";
  -webkit-font-feature-settings: "ccmp";
  -moz-font-feature-settings: "ccmp";
  -ms-font-feature-settings: "ccmp";
  font-feature-settings: "ccmp";
  -webkit-font-feature-settings: "locl";
  -moz-font-feature-settings: "locl";
  -ms-font-feature-settings: "locl";
  font-feature-settings: "locl";
  -webkit-font-kerning: normal;
  -moz-font-kerning: normal;
  -ms-font-kerning: normal;
  font-kerning: normal;
  -webkit-font-variant: common-ligatures;
  -moz-font-variant: common-ligatures;
  -ms-font-variant: common-ligatures;
  font-variant: common-ligatures;
}
body .test-text {
  font-size: 36px;
}
body .tnum {
  -webkit-font-feature-settings: "tnum";
  -moz-font-feature-settings: "tnum";
  -ms-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
}
body .pnum {
  -webkit-font-feature-settings: "pnum";
  -moz-font-feature-settings: "pnum";
  -ms-font-feature-settings: "pnum";
  font-feature-settings: "pnum";
}
body sup,
body .sups {
  -webkit-font-feature-settings: "sups";
  -moz-font-feature-settings: "sups";
  -ms-font-feature-settings: "sups";
  font-feature-settings: "sups";
  vertical-align: unset;
}
body sub,
body .subs {
  -webkit-font-feature-settings: "subs";
  -moz-font-feature-settings: "subs";
  -ms-font-feature-settings: "subs";
  font-feature-settings: "subs";
  vertical-align: unset;
}
body .numr {
  -webkit-font-feature-settings: "numr";
  -moz-font-feature-settings: "numr";
  -ms-font-feature-settings: "numr";
  font-feature-settings: "numr";
  vertical-align: unset;
}
body .dnom {
  -webkit-font-feature-settings: "dnom";
  -moz-font-feature-settings: "dnom";
  -ms-font-feature-settings: "dnom";
  font-feature-settings: "dnom";
  vertical-align: unset;
}
body .frac {
  -webkit-font-feature-settings: "frac";
  -moz-font-feature-settings: "frac";
  -ms-font-feature-settings: "frac";
  font-feature-settings: "frac";
}
body .ordn {
  -webkit-font-feature-settings: "ordn";
  -moz-font-feature-settings: "ordn";
  -ms-font-feature-settings: "ordn";
  font-feature-settings: "ordn";
}

/* Font selector */
.select-form {
  position: fixed;
  z-index: 1000;
  top: 15px;
  right: 15px;
}
.select-form #font-selector {
  padding: 02px 5px;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  overflow: hidden;
  background-color: #555;
  color: #fff;
}
.select-form #font-selector:focus {
  outline: none;
}

/*# sourceMappingURL=fonts.css.map */

* {
  font-family: "thonburiregular";
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.datepicker-modal {
  position: fixed!important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  width: 0!important;
}

.MuiOutlinedInput-notchedOutline {
  border: none!important;
}
